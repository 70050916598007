import React from 'react';
import './404.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import DynamicIcon from '../../Components/Helpers/DynamicIcon';
import Fade from 'react-reveal/Fade';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';

export default function NotFound(props) {
    const windowWidth = window.innerWidth;
    GoogleAnalytics('/404', {});

    function handleHowItWorks() {
        window.location = "/";
    }

    return (
        <>
            <div className="main fof">
                <Header pageName={props.pageName} handleHowItWorks={handleHowItWorks}/>
                <div className="body">
                    <div className="sec-404">
                        <DynamicIcon type="notFound" width={windowWidth > 600 ? '400' : '300'} height={windowWidth > 600 ? '280' : '210'} />
                        <Fade>
                            <h2>Page Not Found</h2>
                            <p>Sorry, this page could not be found. You may want to check <a href="/">homepage</a></p>
                        </Fade>
                    </div>
                </div>
                <Footer windowWidth={windowWidth} pageName='Main'/>
            </div>
        </>
    );
}