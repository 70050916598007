import React, { useEffect, useState, useRef } from 'react';
import './DeleteInstagram.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import Fade from 'react-reveal/Fade';
import LoadingPage from '../../Components/LoadingPage';
import DefineTrafficSource from '../../Components/Helpers/DefineTrafficSource';
import ImageLoader from '../../Components/Helpers/ImageLoader';

export default function DeleteInstagram() {
    GoogleAnalytics('/delete-instagram', {});

    const delete_on_iphone_section = useRef();
    const via_settings_section = useRef();
    const via_link_section = useRef();
    const deactivate_on_iphone_section = useRef();
    const delete_on_pc_or_mac = useRef();

    const windowWidth = window.innerWidth;
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        function handlePageLoad() {
            if (document.readyState === "complete") {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        }

        document.body.classList.add('page-is-loading');

        handlePageLoad();
        document.addEventListener("readystatechange", handlePageLoad);

        return () => {
            document.removeEventListener("readystatechange", handlePageLoad);
            document.body.classList.remove('page-is-loading');
        };
    }, []);

    useEffect(() => {
        DefineTrafficSource();
    }, []);

    const handleLearnMore = () => {
        GoogleAnalytics('', {
            category: "Delete_instagram",
            action: "DI_learn_more"
        });

        window.location.href = '/';
    }

    const handleDeleteOnIphone = () => {
        GoogleAnalytics('', {
            category: "Delete_instagram",
            action: "Delete_Ig_via_iphone_clicked"
        });

        delete_on_iphone_section.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const handleViaSettings = () => {
        GoogleAnalytics('', {
            category: "Delete_instagram",
            action: "DI_via_settings_clicked"
        });

        via_settings_section.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const handleViaLink = () => {
        GoogleAnalytics('', {
            category: "Delete_instagram",
            action: "DI_via_link_clicked"
        });

        via_link_section.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const handleDeactivateOnIphone = () => {
        GoogleAnalytics('', {
            category: "Delete_instagram",
            action: "Deactivate_Ig_via_iphone_clicked"
        });

        deactivate_on_iphone_section.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const handleDeleteOnPcOrMac = () => {
        GoogleAnalytics('', {
            category: "Delete_instagram",
            action: "Deactivate_Ig_on_PC_or_Mac"
        });

        delete_on_pc_or_mac.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const _renderLoadingPage = () => {
        if (!isPageLoaded) return <LoadingPage />
    };

    return (
        <div className='delete-instagram-main'>
            {_renderLoadingPage()}
            <Header pageName='How to Delete and Deactivate Instagram Account: Step-by-Step Guide - Arvel' windowWidth={windowWidth} />
            <Fade>
                <div className='display-flex delete-instagram-container'>
                    <h1 className='di-header'>How to Delete or Deactivate Your Instagram Account</h1>
                    <p className='di-header-footer'>by Megan Foster · 5 min read · Aug 5, 2024</p>
                    <div className='display-flex di-content-overview-container'>
                        <button className='display-flex di-doi' onClick={handleDeleteOnIphone}>Delete on iPhone</button>
                        <button className='display-flex di-daoi' onClick={handleDeactivateOnIphone}>Deactivate on iPhone</button>
                        <button className='display-flex di-dopcmac' onClick={handleDeleteOnPcOrMac}>Delete on PC or Mac</button>
                        <button className='display-flex di-dvs' onClick={handleViaSettings}>Delete via settings</button>
                        <button className='display-flex di-dvdl' onClick={handleViaLink}>Delete via direct link</button>
                    </div>
                    <p className='di-text-description'>Thinking about stepping away from Instagram? You’re definitely not alone! Many of us are choosing to spend less time on social media to focus on what really matters — creating lasting memories.</p>
                    <p className='di-text-description'>This guide will gently walk you through how to delete or temporarily deactivate your Instagram account. Once you’ve made the switch, you can dive into Arvel, where you can track your adventures, find inspiration for new experiences, and inspire others along the way.</p>
                    <div className='di-image-1'>
                        <ImageLoader src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-instagram-img-1.jpg' skeletonH={'100%'} skeletonW={'100%'} skeletonR={4} />
                    </div>
                    <p className='di-img-label'>Jana & Matej's Arvel Profile</p>
                    <button className='display-flex di-explore-arvel-btn' onClick={handleLearnMore}>
                        <img alt="icon-logo" loading='lazy' src='https://s3.us-east-2.amazonaws.com/arvel.photos/logos/icon_logo_circle_transperent.png' />
                        <p>Learn more about Arvel</p>
                    </button>
                    <h2 ref={delete_on_iphone_section} className='di-section-header'>How to <span style={{ textDecoration: 'underline' }}>delete</span> Instagram using your <span style={{ textDecoration: 'underline' }}>iPhone</span></h2>
                    <p className='di-text-description'>To permanently delete your Instagram account, log in and submit a request through your <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/accounts/edit/'>privacy settings</a>. Instagram gives you 30 days before it’s gone for good. During that time, your account stays hidden, and you can cancel the deletion if you sign back in.</p>
                    <p className='di-text-description'>Here’s how to delete Instagram from your iPhone in five easy steps:</p>
                    <ul className='di-text-description-ul'>
                        <li className='di-text-description-li'>
                            <p>Go to your <strong>Account Profile</strong>{' > '}<strong>menu</strong>.</p>
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/1.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Open <strong>Accounts Center</strong> {' > '} <strong>Personal details</strong>.
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/2.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/3.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Select <strong>Account ownership and control</strong> {' > '} <strong>Deactivation or deletion</strong>.
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/4.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/5.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Select the account you want to remove, choose <strong>Delete account</strong>, and tap <strong>Continue</strong>.
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/6.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/7.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Choose a reason for leaving, tap <strong>Continue</strong>, and sign in again to confirm you want to permanently delete your Instagram account.
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/8.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                    </ul>

                    <h2 ref={via_link_section} className='di-section-header'>Use a <span style={{ textDecoration: 'underline' }}>direct link</span> to quickly delete your Instagram account—no need to dig through settings</h2>

                    <ul className='di-text-description-ul'>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Sign in to Instagram and head to the page where you can <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/accounts/remove/request/permanent/'>request permanent deletion</a>.
                        </li>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Open the <strong>dropdown menu</strong> and choose a reason for leaving.
                        </li>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Click <strong>Delete</strong>.
                        </li>
                    </ul>

                    <h2 ref={via_settings_section} className='di-section-header'>Delete Instagram <span style={{ textDecoration: 'underline' }}>via settings</span></h2>

                    <ul className='di-text-description-ul'>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Sign into Instagram.
                        </li>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Go to <strong>More</strong> {' > '} <strong>Settings</strong> {' > '} <strong>Accounts Center</strong>.
                        </li>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Select <strong>Personal details</strong> {' > '} <strong>Account ownership and control</strong> {' > '} <strong>Deactivation or deletion</strong>.
                        </li>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Select the account you want to delete, click <strong>Delete Account</strong> {' > '} <strong>Continue</strong>, then enter your password to confirm.
                        </li>
                        <li className='di-text-description-li di-td-li-margin-bottom'>
                            Select a reason for deleting Instagram, then click <strong>Continue</strong>.
                        </li>
                        <li className='di-text-description-li di-td-li-margin-bottom'>Click <strong>Delete account</strong> to confirm.</li>
                    </ul>

                    <h2 ref={deactivate_on_iphone_section} className='di-section-header'>How to <span style={{ textDecoration: 'underline' }}>deactivate</span> Instagram using your iPhone</h2>
                    <p className='di-text-description'>You can easily deactivate your account right from your phone by heading to the settings menu in your profile. Look for the 'Account ownership and control' options under the Account Center.</p>
                    <p className='di-text-description'>Deactivating your account hides it for 30 days, but don’t worry—nothing gets deleted. If you’re ready to come back, just log in anytime, or if you need a little more time away, you can restart your 30-day deactivation period.</p>
                    <p className='di-text-description'>Here’s how to deactivate your Instagram account on your iOS:</p>
                    <ul className='di-text-description-ul'>
                        <li className='di-text-description-li'>
                            <p>Go to your <strong>Account Profile</strong>{' > '}<strong>menu</strong>.</p>
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/1.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Open <strong>Accounts Center</strong> {' > '} <strong>Personal details</strong>.
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/2.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/3.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Select <strong>Account ownership and control</strong> {' > '} <strong>Deactivation or deletion</strong>.
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/4.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/5.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Select the account you want to deactivate, choose <strong>Deactivate account</strong>, and <strong>sign in again</strong> to confirm deactivation..
                            <div className='display-flex di-li-phone-screen-container'>
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/di/6.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                                <ImageLoader imgClass='display-flex di-li-phone-screen' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/iphone-screen/dai/7.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                    </ul>

                    <h2 ref={delete_on_pc_or_mac} className='di-section-header'>How to delete or deactivate Instagram on a <span style={{ textDecoration: 'underline' }}>PC</span> or <span style={{ textDecoration: 'underline' }}>Mac</span></h2>
                    <p className='di-text-description'>The easiest way to delete or deactivate your Instagram is on a PC or Mac—no need to fuss with all the extra steps the app makes you go through!</p>
                    <p className='di-text-description'>And if you change your mind, no worries! Just log back in within 30 days to cancel the deletion process.</p>
                    <p className='di-text-description'>Here’s how to deactivate your Instagram account on your PC or Mac:</p>
                    <ul className='di-text-description-ul'>
                        <li className='di-text-description-li'>
                            Follow this <a rel="noopener noreferrer" target="_blank" href='https://accountscenter.instagram.com/personal_info/account_ownership_and_control/deletion_and_deactivation'>link</a> to head over to <strong>Accounts Center</strong>
                        </li>
                        <li className='di-text-description-li'>
                            Sign in to your Instagram account
                            <div className='display-flex di-li-web-dda-s1-container'>
                                <ImageLoader imgClass='display-flex di-li-web-dda-s1' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/web/di-dai/1.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Select the account you want to delete or deactivate
                            <div className='display-flex di-li-web-dda-s2-container'>
                                <ImageLoader imgClass='display-flex di-li-web-dda-s2' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/web/di-dai/2.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                        <li className='di-text-description-li'>
                            Choose deactivate or delete, and sign in again to confirm...and you're all set!
                            <div className='display-flex di-li-web-dda-s3-container'>
                                <ImageLoader imgClass='display-flex di-li-web-dda-s3' src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/delete-ig/web/di-dai/3.png' skeletonH={'100%'} skeletonW={'100%'} skeletonR={10} />
                            </div>
                        </li>
                    </ul>

                    <h2 className='di-section-header'>Create lasting memories and get inspired for new experiences with Arvel community</h2>
                    <p className='di-text-description'>Spending too much time on Instagram and other social media can really mess with your sleep, drain your productivity, and take the fun out of life. It can even lead to more anxiety and depression. Instead, try Arvel! You can build a history of your favorite memories with an interactive map and fun stats. Discover experiences that inspire you and plan your next adventure!</p>
                    <button className='display-flex di-explore-arvel-btn' onClick={handleLearnMore}>
                        <img alt="icon-logo" loading='lazy' src='https://s3.us-east-2.amazonaws.com/arvel.photos/logos/icon_logo_circle_transperent.png' />
                        <p>Learn more about Arvel</p>
                    </button>
                </div>
            </Fade>
            <Footer windowWidth={windowWidth} />
        </div>
    );
}