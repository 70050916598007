import React, { useState, useEffect, useRef } from 'react';
import { Skeleton } from '@mantine/core';

const ImageLoader = ({ src, imgClass, skeletonH, skeletonW, skeletonR }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (imgRef.current) observer.observe(imgRef.current);

        return () => observer.disconnect();
    }, []);

    const _renderImg = () => {
        return (
            <div
                ref={imgRef}
                style={{
                    position: 'relative',
                    height: skeletonH,
                    width: skeletonW,
                    overflow: 'hidden'
                }}
                className={imgClass}>
                {
                    !imageLoaded
                    && <Skeleton
                        radius={skeletonR}
                        height={skeletonH}
                        width={skeletonW}
                        style={{ position: 'absolute', top: 0, left: 0 }} />
                }

                {
                    isVisible
                    && src
                    && <img
                        src={src}
                        alt="img"
                        loading="lazy"
                        style={{
                            display: 'block',
                            transition: 'opacity 0.5s ease-in'
                        }}
                        onLoad={() => setImageLoaded(true)}
                    />
                }
            </div>
        );
    };

    return <>{_renderImg()}</>;
};

export default ImageLoader;
