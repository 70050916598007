import React, { useRef, useEffect, useState } from 'react';
import './Main.css';
import Footer from '../../Components/Footer';
import Intro from './components/Intro';
import IntroBanner from './components/IntroBanner';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import ExploreProfiles from './components/ExploreContributorProfiles';
import SolutionDescription from './components/SolutionDescription';
import DefineTrafficSource from './../../Components/Helpers/DefineTrafficSource';
import TravelPassportPoster from './components/TravelPassportPoster';
import LoadingPage from '../../Components/LoadingPage';

export default function Main() {
    GoogleAnalytics('/main', {});

    const introLearnMoreRef = useRef();
    const chooseCreatorRef = useRef();
    const experiencePassport = useRef();
    const windowWidth = window.innerWidth;
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        function handlePageLoad() {
            if (document.readyState === "complete") {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        }

        document.body.classList.add('page-is-loading');

        handlePageLoad();
        document.addEventListener("readystatechange", handlePageLoad);

        return () => {
            document.removeEventListener("readystatechange", handlePageLoad);
            document.body.classList.remove('page-is-loading');
        };
    }, []);

    useEffect(() => {
        DefineTrafficSource();
    }, []);

    const handleIntroLearnMore = () => {
        introLearnMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

        GoogleAnalytics('', {
            category: "Main_page",
            action: "Main_intro_learn_more"
        });
    };

    const handleJoinCommunity = () => experiencePassport.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

    const _renderLoadingPage = () => {
        if (!isPageLoaded) return <LoadingPage />
    };

    return (
        <div id="main" className="main main-page">
            {_renderLoadingPage()}
            <Intro pageName='Main' handleIntroLearnMore={handleIntroLearnMore} windowWidth={windowWidth} />
            <div id='main-body-container' style={{
                opacity: (isPageLoaded ? 1 : 0)
            }}>
                <IntroBanner windowWidth={windowWidth} ref={introLearnMoreRef} />
                <SolutionDescription />
                <ExploreProfiles windowWidth={windowWidth} ref={chooseCreatorRef} handleJoinCommunity={handleJoinCommunity} />
                <TravelPassportPoster windowWidth={windowWidth} ref={experiencePassport} />
            </div>
            <Footer windowWidth={windowWidth} pageName='Main' />
        </div>
    );
};