import React from 'react';
import './IntroBanner.css';
import Fade from 'react-reveal/Fade';
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';

const IntroBanner = React.forwardRef((props, ref) => {
    const { windowWidth } = props;

    return (
        <div ref={ref} id='banner-frame' className="banner-frame banner-frame-intro">
            <div className="banner-container md:shadow-lg">
                <Fade>
                    <div className='bc-body'>
                        <Fade>
                            <div className='banner-img'>
                                <DynamicIcon type='introBanner' height={(windowWidth < 700 ? 200 : 250)} width={(windowWidth < 700 ? 200 : 250)} />
                            </div>
                        </Fade>
                        <Fade>
                            <div className='bc-body-txt'>
                                <p className='bcbt-hdr'>Track, share memories & get inspired for new experiences</p>
                            </div>
                        </Fade>
                    </div>
                </Fade>
            </div>
        </div>
    );
});

export default IntroBanner;