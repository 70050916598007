import React from 'react';
import './Components.css';
import PulseLoader from "react-spinners/PulseLoader";

export default function LoadingPage() {

    return (
        <div className="loading-page">
            <PulseLoader color='#fff' size={9} />
        </div>
    );
}