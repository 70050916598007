import React, { useState, useEffect } from 'react';
import './TravelPassportPoster.css';
import GoogleAnalytics from '../../../Components/Helpers/GoogleAnalytics';
import Fade from 'react-reveal/Fade';
import CountUp from 'react-countup';
import ImageLoader from '../../../Components/Helpers/ImageLoader';
import SubscribeWaitlistModal from '../../../Components/SubscribeWaitlistModal';
import DefineTrafficSource from '../../../Components/Helpers/DefineTrafficSource';

const TravelPassportPoster = React.forwardRef((props, ref) => {
    const { windowWidth } = props;
    const [slideNum, setSlideNum] = useState(0);
    const [nodeViewsCount, setNodeViewsCount] = useState(0);
    const [isJoined, setIsJoined] = useState(false);

    const list = [
        {
            name: 'Petra & Jan',
            url: '/creators/wanderingintwo',
            location: 'Vancouver, Canada',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/wanderingintwo.jpg'
        },
        {
            name: 'Ryan & Katy',
            url: '/creators/smilkos_lens',
            location: 'Santa Rosa, USA',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/smilkos_lens_2.webp'
        },
        {
            name: 'Jana & Matej',
            url: '/creators/dreamyadventurers',
            location: 'Banff, Canada',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/dreamyadventurers_2.jpg'
        },
        {
            name: 'Elnar',
            url: '/creators/eelnar',
            location: 'San Francisco, USA',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/pp-eelnar-187901.webp'
        },
        {
            name: 'Kyle & Alannah',
            url: '/creators/wayoftheroads',
            location: 'Tofino, Canada',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/wayoftheroads_2.webp'
        }
    ]

    useEffect(() => {
        const slideInterval = setInterval(() => {
            renderSlide();
        }, 4000)

        return () => clearInterval(slideInterval);
    });

    useEffect(() => {
        DefineTrafficSource();
    }, []);

    const renderSlide = () => {
        if (slideNum === 4) setSlideNum(0);
        else setSlideNum(slideNum + 1);
    };

    const handleViewProfile = (URL) => {
        GoogleAnalytics('', {
            category: "Waitlist_web_poster",
            action: 'Waitlist_web_poster_view_profile'
        });

        if (windowWidth > 601) window.open(URL);
        else window.location.href = URL;
    };

    const handleUpdate = (count, isJoined) => {
        setNodeViewsCount(count);
        setIsJoined(isJoined);
    };

    const _renderCoutUp = () => {
        if (isJoined) {
            return <h3>{'Join ' + nodeViewsCount.toLocaleString() + ' on waitlist'}</h3>
        } else {
            return <h3>
                <span>Join </span>
                <CountUp
                    delay={0.5}
                    start={2500}
                    end={nodeViewsCount}
                    duration={3.5}
                />
                <span> on waitlist</span>
            </h3>
        }
    };

    return (
        <div ref={ref} className='weekly-newsletter-container travel-passport-container'>
            <Fade>
                <div className="weekly-newsletter-div travel-passport-div">
                    <div className='hdr-container'>
                        <h2>Join community</h2>
                    </div>
                    <div className="banner-frame">
                        <div className="banner-container md:shadow-lg">
                            <Fade>
                                <div className='bc-body'>
                                    <div className='banner-img'>
                                        <div className='si-img-div' onClick={() => handleViewProfile(list[slideNum].url)}>
                                            {
                                                list.map(function (creator, index) {
                                                    return <>
                                                        {
                                                            index === slideNum &&
                                                            <ImageLoader src={creator.img} imgClass={'display-flex si-img-container'} />
                                                        }
                                                    </>
                                                })
                                            }
                                            <p className='si-name-hdr'>{list[slideNum].name}</p>
                                            <p className='si-location-hdr'>{list[slideNum].location}</p>
                                        </div>
                                    </div>
                                    <div className='bc-body-txt'>
                                        {_renderCoutUp()}
                                        <p>Build, track, and share your memories with fun stats and interactive map, while growing a community around your adventures.</p>
                                        <div className='wtb-div'>
                                            <SubscribeWaitlistModal handleUpdate={(count, isJoined) => handleUpdate(count, isJoined)} />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </Fade>
            <div className='ep-cover' />
        </div>
    );
});

export default TravelPassportPoster;