import React, { useEffect, useState } from 'react';
import './OurStory.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import Fade from 'react-reveal/Fade';
import ImageLoader from '../../Components/Helpers/ImageLoader';
import LoadingPage from '../../Components/LoadingPage';
import DefineTrafficSource from '../../Components/Helpers/DefineTrafficSource';

export default function OurStory() {
    GoogleAnalytics('/our-story', {});
    
    const windowWidth = window.innerWidth;
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        function handlePageLoad() {
            if (document.readyState === "complete") {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        }

        document.body.classList.add('page-is-loading');

        handlePageLoad();
        document.addEventListener("readystatechange", handlePageLoad);

        return () => {
            document.removeEventListener("readystatechange", handlePageLoad);
            document.body.classList.remove('page-is-loading');
        };
    }, []);

    useEffect(() => {
        DefineTrafficSource();
    }, []);

    const _renderLoadingPage = () => {
        if (!isPageLoaded) return <LoadingPage />
    };

    return (
        <div className='our-story-main'>
            {_renderLoadingPage()}
            <Header pageName='Our story - Arvel' windowWidth={windowWidth} />
            <Fade>
                <div className='display-flex our-story-container'>
                    <h1 className='os-header'>Our story: Experience world from different angles</h1>
                    <p className='os-header-footer'>by <a target='_blank' rel='noopener noreferrer' href='https://betahunt.io/experience-sharing-platform-arvel-founder-interview/'>BetaHunt</a> · 3 min read · Sep 5, 2024</p>

                    <div className='os-image-1'>
                        <ImageLoader src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/our-story-img-1.jpg' skeletonH={'100%'} skeletonW={'100%'} skeletonR={4} />
                    </div>
                    <p className='os-img-label'>Happiness lies in living life's little experiences</p>

                    <h3 className='os-question'>What’s your background, and what inspired you to create Arvel?</h3>
                    <p className='os-response'>Hey, Elnar is here. To be frank, I have quite a versatile background,
                        I started my career as a petroleum engineer, then transitioned into the finance world and worked as
                        an investment banker in my last corporate job. Driven by a passion for building things and seeing
                        them in use, I self-taught programming. 2 years ago, I left my full-time job to co-found Arvel with
                        friends. Arvel (Archived Travel) is inspired by our innate curiosity to experience the world from different angles.</p>

                    <h3 className='os-question'>How do you define a successful experience on Arvel, your experience sharing platform?</h3>
                    <p className='os-response'>To give you a bit of context what we mean by an experience on Arvel. We believe
                        that everyone experiences the world uniquely, with their favorite places to eat, hike, swim, travel, play games, and more.
                        Our goal is to create an interactive experience sharing platform to help people capture that joyful
                        moment via images and summaries to help others discover them – If others can experience the same happiness
                        I think that would be a success.</p>

                    <h3 className='os-question'>What were the biggest challenges you faced developing Arvel, and how did you overcome them?</h3>
                    <p className='os-response'>Let me put you in our shoes to give you a better sense of it. Year 2022, friends and I chipped
                        in and co-founded Arvel. We hired few folks (part-time, contract, freelance etc.) to build MVP and get it out to the market.
                        Year 2023, the VC world is infatuated with the AI hype, leaving us with zero chance to raise even a penny.</p>
                    <p className='os-response'>We had no traction, very limited resources, and a short run-way – imagine racing F1 cars on a bike
                        without a front wheel, that was us. To survive few more iterations, we had to shorten the timeline for a product/feature launch
                        from ideation to market test by 2-3 times. Seems like it worked, we’ve experienced a stroke of serendipity with the current
                        release – 2,000 waitlist signups in a week.</p>

                    <h3 className='os-question'>Can you highlight the main features of Arvel and how they enhance user experience?</h3>
                    <p className='os-response'>Arvel has two main aspects: the opportunity to try new experiences and the ability to
                        build an experience passport. Easily finding experiences that resonate with your interests and preferences, and
                        understanding why they’re worth trying, is a game-changer.</p>

                    <p className='os-response'>Additionally, capturing joyful moments and building up statistics such as the number
                        of cuisines tried, countries visited, kilometers traveled, hikes completed – we believe this makes for a
                        good and cool life, one that you can share with others.</p>

                    <h3 className='os-question'>How does Arvel ensure shared content is engaging and high quality?</h3>
                    <p className='os-response'>Creating a smooth path to guide users in sharing experiences has possibly been the
                        most challenging part of building Arvel. It’s crucial for several reasons: it ensures a fun and engaging flow,
                        aligns shared experiences with our mission, and maintains the high-quality content we aim to provide our users.
                        Meanwhile, through this process, we’ll collect data and categorize each experience, making it easy for users
                        to find experiences that resonate with them.</p>
                    <div className='os-image-3'>
                        <ImageLoader src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/our-story-img-3.jpg' skeletonH={'100%'} skeletonW={'100%'} skeletonR={4} />
                    </div>
                    <p className='os-img-label'>Jana & Matej's Arvel Profile</p>

                    <h3 className='os-question'>How does Arvel encourage users to share experiences and engage with others?</h3>
                    <p className='os-response'>Every experience shared by a user contributes to their profile statistics, showcasing
                        how unique and fun their life is. Also, when someone adds an experience to a bucket list or tries an experience
                        shared by another user, they receive a “+1” (we call it “good karma”, though the name is still WIP).</p>
                    <div className='os-image-4'>
                        <ImageLoader src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/our-story-img-4.jpg' skeletonH='100%' skeletonW={'100%'} skeletonR={4} />
                    </div>
                    <p className='os-img-label'>Explore Arvel: Nanaimo, British Columbia</p>

                    <h3 className='os-question'>What is Arvel’s business model, and how do you plan to generate revenue?</h3>
                    <p className='os-response'>Frankly, we’re not focused on monetization right now. Our primary goal is to create something genuinely
                        useful that brings joy to people’s lives. If we can achieve that, opportunities will present themselves.</p>

                    <a rel="noopener noreferrer" target="_blank" href="https://betahunt.io/experience-sharing-platform-arvel-founder-interview/" className='os-rfs'>Read full story</a>

                    <h1 className='more-reads-header'>More about Arvel</h1>
                    <div className='mr-article-section'>
                        <a rel="noopener noreferrer" target="_blank" className='mr-article-header' href="https://medium.com/@starshine_37804/resilience-and-inspiration-two-years-seven-pivots-and-the-heartwarming-surprise-of-2-500-59ef7891451f">Resilience and Inspiration: Two Years, Seven Pivots, and the Heartwarming Surprise of 2,500 Waitlist Sign-Ups in Just One Week!</a>
                        <p className='mr-article-header-footer'>by <a target='_blank' rel='noopener noreferrer' href='https://medium.com/@starshine_37804'>Lisa-Anne Sanderson</a> · 4 min read · Sep 29, 2024</p>
                    </div>
                    <br/>
                </div>
            </Fade>
            <Footer windowWidth={windowWidth} />
        </div>
    );
}