import React from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as notFound from "../../lottie/not-found.json";
import * as contactUs from "../../lottie/contact-us.json";
import * as introBanner from '../../lottie/intro-banner.json';
import * as messageSentSuccess from '../../lottie/message-sent-success.json';
import * as refund from '../../lottie/refund.json';

export default function DynamicIcon(props) {
    var iconList = {
        notFound: {
            loop: true,
            autoplay: true,
            animationData: notFound.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        contactUs: {
            loop: true,
            autoplay: true,
            animationData: contactUs.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        introBanner: {
            loop: true,
            autoplay: true,
            animationData: introBanner.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        messageSentSuccess: {
            loop: true,
            autoplay: true,
            animationData: messageSentSuccess.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        refund: {
            loop: true,
            autoplay: true,
            animationData: refund.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        }
    };

    return (
        !props.type
            ?
            ''
            :
            <FadeIn>
                <div className="d-flex justify-content-center align-items-center sr-lottie">
                    <Lottie options={iconList[props.type]} height={parseInt(props.height)} width={parseInt(props.width)} />
                </div>
            </FadeIn>
    );
}